import React, { useEffect, useState } from "react";
import Crousel from "../../Tools/Crousel";
import { useTranslation } from "react-i18next";
import ListItem from "../../Tools/ListItem";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCarDetails } from "../../../Reducer/singleCarSlice";
import OtherCar from "./otherCar";
import CarDetailModel from "../../Tools/CarDetailModel";
import {
  fetchFilteredSearchResults,
  fetchSingleCar,
  initiateChat,
  likeDislikePost,
  userActivity,
} from "../../../Reducer/api";
import { fetchSaved } from "../../../Reducer/savedSlice";
import {
  beginWithImage,
  findUploads,
  formatPrice,
  getNameFromVal,
  itemLimit,
  numberWithCommas,
  userActivityLocal,
} from "../../../utils/constants";
import { Button, Modal, ModalBody } from "reactstrap";
import EnquiryForm from "../Enquiry/EnquiryForm";
import SinglePageRight from "./singlePageRight";
import ShareModal from "../../Tools/ShareModal";
import { apiManager } from "../../../api/connectAPI";
import moment from "moment";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react/dist/iconify.js";
import { paths } from "../../../utils/paths";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";

export default function SingleCar({ page }) {
  // let data = useSelector((state) => state.singleCar.detail);
  // const loading = useSelector((state) => state.singleCar.loading);
  // const data?.data?.[0] = data?.data?.[0];
  const udata = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [pageloading, setPageLoading] = useState(false);
  const error = useSelector((state) => state.singleCar.error);
  const [params] = useSearchParams();
  const adId = params.get("adId");
  const sold = params.get("sold");

  useEffect(() => {
    const adIdToChat = localStorage.getItem("adIdToChat");
    if (adIdToChat) {
      const tmp = localStorage.getItem("chatInitiated");
      if (tmp == "true") {
        localStorage.setItem("chatInitiated", "false");
        localStorage.setItem("adIdToChat", "");
        handleInitiateChat(adIdToChat);
      }
    }
  }, [localStorage]);

  useEffect(() => {
    setPageLoading(true);
    // userActivityLocal(adId)
    // dispatch(fetchCarDetails(form));
    userActivity({ adId: adId, type: "view" });

    if (localStorage.getItem("token")) {
      dispatch(fetchSaved());
    }

    let form = { id: adId, page: page, sold: sold };
    fetchSingleCar(form).then((res) => {
      console.log(res);
      setData(res);
      const ad = res?.data?.[0]
      const title = t(ad?.properties?.basicDetails?.make?.[lang]) +" "+ t(ad?.properties?.basicDetails?.model?.[lang])
      document.title = title
      setPageLoading(false);
    });
  }, [dispatch, params.get("adId")]);
  const savedCars = useSelector((state) => state.saved.saved)?.ads?.map(
    (v) => v._id
  );
  const saveLoad = useSelector((state) => state.saved.loading)
  const { t, i18n } = useTranslation();

  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const navigate = useNavigate();
  const [day, setDay] = useState(page);
  const [dealerCars, setDealerCars] = useState([]);


  // const [saveLoad, setLoading] = useState(false);

  const toggleSaveCar = async () => {
    if (!localStorage.getItem("token")) {
      navigate("/login", {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }

    // setLoading(true);
    const perform = savedCars?.includes(adId) ? "remove" : "add";
    const res = await likeDislikePost(adId, perform,);
    dispatch(fetchSaved());
  };

  const handleChange = (e) => {
    setRadio(e.target.value);
    setDay(e.target.value);
  };

  const [all_images, set_all_images] = useState([]);
  useEffect(() => {
    if (data?.data?.[0]) {
      const ad = data?.data?.[0];
      let all_list = findUploads(ad);
      set_all_images(all_list);
    }
  }, [data?.data?.[0]]);

  const getDealerCars = () => {
    setDealerCars([]);
    fetchFilteredSearchResults({
      page: page,
      limit: itemLimit,
      currentPage: 0,
      brandId: null,
      ownerId: data?.data?.[0]?.owner?._id,
    }).then((res) => {
      const withoutCurrent = res?.data?.filter((v) => v._id !== adId);
      setDealerCars(withoutCurrent);
    });
  };

  useEffect(() => {
    if (data?.data?.[0]?.owner?._id) {
      getDealerCars();
    }
  }, [params.get("adId"), data]);

  const getPrice = () => {
    switch (day) {
      case "normal":
        return `${formatPrice(lang, data?.data?.[0]?.askPrice, data?.data?.[0]?.dollorAskPrice)}`;
      case "daily":
        return `${formatPrice(lang, data?.data?.[0]?.askPrice, data?.data?.[0]?.dollorAskPrice)}/${t("Daily")}`;
      case "weekly":
        return `${formatPrice(lang, data?.data?.[0]?.askPrice1, data?.data?.[0]?.dollorAskPrice1)}/${t(
          "Weekly"
        )}`;
      case "monthly":
        return `${formatPrice(lang, data?.data?.[0]?.askPrice2, data?.data?.[0]?.dollorAskPrice2)}/${t(
          "Monthly"
        )}`;
      case "rental":
        return `${formatPrice(lang, data?.data?.[0]?.askPrice, data?.data?.[0]?.dollorAskPrice)}/${t("Daily")}`;
    }
  };

  const [radioState, setRadio] = useState("daily");

  const [readMore, setReadMore] = useState(false);

  const [enquireModal, setEnquireModal] = useState(false);
  const handleCloseEnquireModal = () => {
    setEnquireModal(false);
  };

  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });
  const handleInitiateChat = (id) => {
    if (chatNow.loading) return;
    if (!udata.token) {
      navigate(paths.login, {
        state: {
          backPage: location.pathname + location.search,
          request: "chat",
          adIdToChat: id,
        },
      });
      return;
    }
    setChatNow({ ...chatNow, adId: id, loading: true });
    initiateChat(id)
      .then((res) => {
        if (res.status) {
          navigate(`${paths.messages}?id=${res.chatId}`);
        }
        setChatNow({ ...chatNow, loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStartEnquiry = () => {
    if (!udata.token) {
      setEnquireModal(true);
      return;
    }
    handleEnquireLoggedIn();
  };
  const [enquireLoading, setEnquireLoading] = useState(false);

  const handleEnquireLoggedIn = () => {
    setEnquireLoading(true);
    const formData = {
      adId: adId,
      messageMetadata: {
        type: "enquiry",
        enquiryType: "test drive",
        enquiryDate: moment(new Date()).format("YYYY-MM-DD"),
      },
      enquiry: false,
      fullName: udata?.auth?.fullName,
      email: udata?.auth?.email,
      mobile: udata?.auth?.phoneNumber,
      message: "",
    };
    // console.log(formData);

    apiManager("POST", `chat/init?lang=${lang}`, formData)
      .then((res) => {
        if (res.status) {
          navigate(paths.messages, {
            state: { chatId: res.chatId },
          });
        } else {
          console.log(res);
          toast.error(res.message);
        }
        setEnquireLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        setEnquireLoading(false);
      });
  };

  const [sharePop, setSharePop] = useState(false);
  const handleCloseSharePop = () => {
    setSharePop(false);
  };
  return pageloading ? (
    <div
      class="d-flex justify-content-center"
      style={{ minHeight: "100vh", marginTop: "25vh" }}
    >
      <div class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      {data?.data?.[0] && Object.keys(data?.data?.[0]).length > 0 ? (
        <section class="singlepage">
          <div class="container">
            <h6 class="back">
              <a onClick={() => navigate(-1)}>
                <img src="../assets/images/backbt.png" /> {t("Go back")}
              </a>
            </h6>
            <div class="row">
              <div class="col-lg-6 col-xxl-7">
                <div
                  class="singlepage_left"
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  {data?.data?.[0]?.owner?.userType !== "dealer" && (
                    <p
                      className="owl-item-badge"
                      style={{
                        zIndex: 9,
                        top: "0px",
                        // left: "0px",
                        [lang === "en" ? "left" : "right"]: "0px",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          borderRadius:
                            lang !== "en"
                              ? "0px 8px 0px 8px"
                              : "8px 0px 8px 0px",
                          fontSize: "11px",
                          backgroundColor: "#334660",
                        }}
                        class=" text-light float-start px-4"
                      >
                        {t("Private ad")}
                      </span>
                    </p>
                  )}
                  <Crousel data={all_images ? all_images : []} />
                  <SinglePageRight
                    classNames={"singlepage_right d-lg-none d-block"}
                    page={page}
                    data={data}
                    radioState={radioState}
                    handleChange={handleChange}
                    getPrice={getPrice}
                    lang={lang}
                    params={params}
                    handleInitiateChat={handleInitiateChat}
                    chatNow={chatNow}
                    setChatNow={setChatNow}
                  />

                  <h5 style={{ position: "unset" }}>
                    {t("Car Details")}
                    <ul
                      class="butm d-flex saveShare"
                      style={{ [lang === "en" ? "right" : "left"]: "2px" }}
                    >
                      <Button
                        id={`PopoverFocus${data?.data?.[0]?._id}`}
                        type="button"
                        onClick={() => setSharePop(true)}
                        className="btn-sm btn-light"
                        style={{
                          backgroundColor: "transparent",
                          border: "unset",
                          padding: "0px",
                        }}
                      >
                        <li className="d-flex align-items-center justify-content-start gap-1">
                          {/* <img
                            src="../assets/images/ser1.png"
                            alt="img"
                            style={{ marginTop: "-1px" }}
                          /> {" "} */}
                          <Icon
                            icon="ri:share-line"
                            fontSize={20}
                            style={{ color: "#4c7ee8" }}
                          />
                          <span
                            className="d-none d-sm-inline"
                            style={{ fontSize: "15px" }}
                          >
                            {t("Share")}
                          </span>
                        </li>
                      </Button>
                      <ShareModal
                        data={data?.data?.[0]}
                        open={sharePop}
                        close={handleCloseSharePop}
                      />
                      {saveLoad ? (
                        <span className="w-100 d-flex justify-content-center loader">
                          <button
                            class="btn btn-light"
                            type="button"
                            disabled
                            style={{ padding: "0px" }}
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden" role="status">
                              Loading...
                            </span>
                          </button>
                        </span>
                      ) : (
                        <li
                          role="button"
                          className="d-flex align-items-center justify-content-start gap-1"
                          onClick={() => toggleSaveCar()}
                        >
                          {/* <img
                            src={`assets/images/${savedIcon}`}
                            alt="img"
                            style={{ marginTop: "-1px" }}
                          />{" "} */}
                          <Icon
                            icon={
                              !savedCars?.includes(data?.data?.[0]?._id)
                                ? "bx:heart"
                                : "bxs:heart"
                            }
                            fontSize={20}
                            style={{ color: "#4c7ee8" }}
                          />
                          <span
                            className="d-none d-sm-inline"
                            style={{ fontSize: "15px" }}
                          >
                            {savedCars?.includes(data?.data?.[0]?._id)
                              ? t("heartSaved")
                              : t("Save")}
                          </span>
                        </li>
                      )}
                    </ul>
                  </h5>
                  {data?.data?.[0]?.properties?.specifications?.km ||
                  data?.data?.[0]?.properties?.specifications?.km == 0 ? (
                    <h6>
                      <img src="../assets/images/adt.png" align="icon" />{" "}
                      {`${numberWithCommas(
                        data?.data?.[0]?.properties?.specifications?.km
                      )} ${t("KMs")}`}
                    </h6>
                  ) : (
                    <></>
                  )}
                  <ul class="hatchback">
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil1.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {t(
                              data?.data?.[0]?.properties?.specifications
                                ?.bodyType?.[lang]
                            )}
                          </span>
                        </>
                      }
                    />
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil2.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {t(
                              data?.data?.[0]?.properties?.specifications
                                ?.engineSize
                                ? getNameFromVal(
                                    "size",
                                    data?.data?.[0]?.properties?.specifications
                                      ?.engineSize,
                                    lang
                                  )
                                : "--"
                            )}
                          </span>
                        </>
                      }
                    />
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil3.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {t(
                              data?.data?.[0]?.properties?.specifications
                                ?.gearbox?.[lang]
                            )}
                          </span>
                        </>
                      }
                    />
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil5.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {t(
                              data?.data?.[0]?.properties?.specifications
                                ?.fuelType?.[lang]
                            )}
                          </span>
                        </>
                      }
                    />
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil4.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {t(
                              data?.data?.[0]?.properties?.specifications?.doors
                            )}{" "}
                            {t("Doors")}
                          </span>
                        </>
                      }
                    />
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil6.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {t(
                              data?.data?.[0]?.properties?.specifications?.seats
                            )}{" "}
                            {t("Seats")}
                          </span>
                        </>
                      }
                    />
                  </ul>
                  {data?.data?.[0]?.properties?.features?.carDescription?.[
                    lang
                  ]?.charAt(0) !== "["}
                  {data?.data?.[0]?.properties?.features?.carDescription?.[
                    lang
                  ] &&
                  data?.data?.[0]?.properties?.features?.carDescription?.[
                    lang
                  ]?.charAt(0) !== "[" ? (
                    <>
                      <h5 class="mb-3">{t("Description")}</h5>
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: readMore ? 100 : 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          // idth: "350px",
                        }}
                      >
                        {data?.data?.[0]?.properties?.features
                          ?.carDescription?.[lang]
                          ? data?.data?.[0]?.properties?.features
                              ?.carDescription?.[lang]
                          : "--"}
                      </p>
                      {data?.data?.[0]?.properties?.features?.carDescription?.[
                        lang
                      ]?.length > 190 && (
                        <a
                          className="mb-3"
                          onClick={() => {
                            setReadMore(!readMore);
                          }}
                        >
                          {readMore ? t("See less") : t("See more")}
                        </a>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  <h5
                    class="spe"
                    style={{ cursor: "pointer" }}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    {t("Specifications")}{" "}
                    <img src="../assets/images/aron.png" align="aro" />
                  </h5>
                </div>
              </div>
              <div class="col-lg-6 col-xxl-5">
                <SinglePageRight
                  classNames={"singlepage_right d-lg-block d-none"}
                  page={page}
                  data={data}
                  radioState={radioState}
                  handleChange={handleChange}
                  getPrice={getPrice}
                  lang={lang}
                  params={params}
                  handleInitiateChat={handleInitiateChat}
                  chatNow={chatNow}
                  setChatNow={setChatNow}
                />

                {data?.data?.[0]?.owner?._id !== udata.auth.id && (
                  <div
                    className="singlepage_right"
                    style={{ marginTop: "-20px" }}
                  >
                    <div class="inset">
                      <h5>
                        {t(
                          `Want to ${
                            page == "normal" ? "buy" : "rent"
                          } this car?`
                        )}
                      </h5>
                      <p>
                        {t("Total Price")} <span>{getPrice()}</span>
                      </p>
                      <button
                        style={{ border: "none" }}
                        disabled={enquireLoading}
                        class="Enquire_Now"
                        onClick={() => handleStartEnquiry()}
                      >
                        {enquireLoading
                          ? t("Please wait...")
                          : t("Enquire Now")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {data?.data?.[0]?.properties && (
        <CarDetailModel
          data={data?.data?.[0]?.properties ? data?.data?.[0]?.properties : []}
          title={t("Specifications")}
        />
      )}
      {dealerCars?.length > 0 ? (
        <OtherCar page={page} data={dealerCars} carData={data?.data?.[0]} />
      ) : null}
      <Modal
        isOpen={enquireModal}
        toggle={handleCloseEnquireModal}
        size="lg"
        centered
      >
        <ModalBody>
          <a
            className="float-end p-2"
            onClick={() => handleCloseEnquireModal()}
          >
            <img src="../assets/images/close_img.png" alt="icon" height={22} />
          </a>
          <div>
            <section class="login enquiry" style={{ padding: "unset" }}>
              <div class="container">
                <EnquiryForm
                  adData={data?.data?.[0]}
                  adId={adId}
                  handleCloseEnquireModal={handleCloseEnquireModal}
                />
              </div>
            </section>
          </div>
        </ModalBody>
      </Modal>


      <JsonLDSchemaHandler data={ page == "normal" ? ldSchemas?.singleCar : ldSchemas?.singleRental} />
    </>
  );
}
