import { t } from "i18next";
import React, { useState } from "react";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { Button, Popover, PopoverBody, UncontrolledPopover } from "reactstrap";
import { paths } from "../../../utils/paths";

export default function ShareModal({ data, open, close }) {
  const [copyAlert, setCopyAlert] = useState("");
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${paths.singleCar}?adId=${data?._id}`
    );
    setCopyAlert("Link Copied!");
    close();
  };
  const clickEvent = (e) => {
    console.log(e?.target)
    close()
  }

  return (
    <Popover
      isOpen={open}
      toggle={close}
      placement="bottom"
      target={`PopoverFocus${data?._id}`}
      // trigger="focus"
      className="sharePopover"
    >
      <PopoverBody>
        <div className="d-flex">
          {/* <div className="col-3 d-flex justify-content-center">
            <div>
              <img src="assets/images/copyToClip.png" height={39} alt="" />{" "}
              <br /> <span className="w-100" style={{ fontSize: "12px", textAlign: 'center' }}>{"Copy"}</span>
            </div>
          </div> */}
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => handleCopyLink()}
              className="btn btn-light pt-2"
              style={{ backgroundColor: "transparent", border: "unset" }}
            >
              <div className="d-flex flex-column">
                <img
                  src="../assets/images/copyToClip.png"
                  style={{ marginTop: "-1px" }}
                  height={40}
                  alt=""
                />{" "}
                <span
                  className="w-100"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    marginTop: "1px",
                  }}
                >
                  {"Copy"}
                </span>
              </div>
            </Button>
          </div>

          <div className="d-flex justify-content-center">
            <a
              style={{ padding: "8px 12px 6px" }}
              onClick={clickEvent}
              href={`mailto:?body=${window.location.origin}${paths.singleCar}?adId=${data?._id}`}
            >
              <EmailIcon
                className="rounded-5"
                style={{ width: "40px", height: "40px" }}
              />{" "}
              <br /> <span style={{ fontSize: "12px", color: 'black', textAlign: 'center', display: 'block', marginTop: '3px' }}>{"Email"}</span>
            </a>
          </div>
          <div className="d-flex justify-content-center">
            <WhatsappShareButton
              style={{ padding: "8px 12px 6px" }}
              onClick={clickEvent}
              url={`${window.location.origin}${paths.singleCar}?adId=${data?._id}`}
            >
              <WhatsappIcon
                className="rounded-5"
                style={{ width: "40px", height: "40px" }}
              />{" "}
              <br /> <span style={{ fontSize: "12px" }}>{"Whatsapp"}</span>
            </WhatsappShareButton>
          </div>
          <div className="d-flex justify-content-center">
            <FacebookMessengerShareButton
              style={{ padding: "8px 12px 6px" }}
              onClick={clickEvent}
              url={`${window.location.origin}${paths.singleCar}?adId=${data?._id}`}
            >
              <FacebookIcon
                className="rounded-5"
                style={{ width: "40px", height: "40px" }}
              />{" "}
              <br /> <span style={{ fontSize: "12px" }}>{t("Facebook")}</span>
            </FacebookMessengerShareButton>
          </div>
          <div className="d-flex justify-content-center">
            <TwitterShareButton
              style={{ padding: "8px 12px 6px" }}
              onClick={clickEvent}
              url={`${window.location.origin}${paths.singleCar}?adId=${data?._id}`}
            >
              <XIcon
                className="rounded-5"
                style={{ width: "40px", height: "40px" }}
              />{" "}
              <br /> <span style={{ fontSize: "12px" }}>{"Twitter"}</span>
            </TwitterShareButton>
          </div>
        </div>
      </PopoverBody>
      {/* <p className="fs-6 text-">{copyAlert}</p> */}
    </Popover>
  );
}
