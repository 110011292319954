import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { paths } from "../../../../../utils/paths";

export default function Security({ data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const Basicetails = data.Basicetails;
  var length = Object.keys(data).length;
  const handleClick = () => {
    navigate(paths.editSecurity, { state: { data: Basicetails } });
  };
  return (
    <div>
      {length > 0 ? (
        <>
          <h3 class="securityh3">
            {t("Security")}{" "}
            <a onClick={handleClick} class="edt">
              <img src="../assets/images/pencil.png" alt="pencil" />{" "}
              {t("Edit password")}
            </a>
          </h3>
          <div class="row">
            <div class="col-sm-3">
              <div class="stp">
                <img src="../assets/images/check-circle2.png" alt="img" />
                <h6>{t("Password")}</h6>
                <h6>*************************</h6>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
