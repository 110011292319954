import React, { useEffect, useState } from "react";
import ListItem from "../../../../Tools/ListItem";
import { sideBarList } from "../../content";
import { useTranslation } from "react-i18next";

import { clearToken, clearUser } from "../../../../../Reducer/authSlice";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import { paths } from "../../../../../utils/paths";
import { useSelector } from "react-redux";
import i18n from "../../../../../i18n";
import { Badge } from "@mui/material";
import { checkProfileComplete } from "../../../../../utils/constants";

export default function Sidebar({ component }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setPage] = useState("My Account");

  const profileData = useSelector((state) => state.myprofile.profilearr);

  useEffect(() => {
    let index = -1;
    let item = sideBarList.filter((v, i) => {
      if (location.pathname.endsWith(v.segment)) {
        index = i;
        return true;
      }
    })?.[0];
    if (!item || item?.length == 0) {
      item = sideBarList[0];
      index = 0;
    }
    console.log("item", item, "index", index);
    // handleClick(item, index)
    $(".nav-link").removeClass("active");
    $("#gt-" + index).addClass("active");
    setPage(item.name);
  }, []);

  const handleClick = (item, index) => {
    console.log(item);
    console.log(index);
    $(".nav-link").removeClass("active");
    $("#gt-" + index).addClass("active");
    setPage(item.name);
    if (item.action) {
      dispatch(clearUser());
      dispatch(clearToken());
      window.location.reload();
      navigate(paths.home);
    } else {
      navigate(`${item.segment}`);
    }
    closeMenu();
  };
  const closeMenu = () => {
    $(".sidebar").removeClass("main");
  };


  return (
    <section class=" close_amountmain ">
      <div class="container">
        <h2 className="d-flex justify-content-between align-items-center">
          <span className="d-none d-lg-block">{t("My Account")} </span>{" "}
          <span className={"d-block d-lg-none"}>{t(currentPage)}</span>{" "}
          <span
            class="filtermenu d-block d-lg-none mt-0"
            style={{ marginTop: "0px !important" }}
            onClick={() => $(".sidebar").addClass("main")}
          >
            <img src="../assets/images/filtermenu.png" alt="pencil" />
          </span>
        </h2>
        <div class="my_account   ">
          <div class="sidebar">
            <span class="filtermenu d-block d-lg-none" onClick={closeMenu}>
              <i class="fa-solid fa-xmark"></i>
            </span>
            <ul className="position-relative">
              {sideBarList && sideBarList.length > 0
                ? sideBarList.map((item, index) => {
                    return (
                      <>
                        <ListItem
                          value={
                            <a
                              className="nav-link "
                              id={`gt-${index}`}
                              onClick={() => handleClick(item, index)}
                            >
                              {item?.segment === paths.messages && (
                                <div
                                  className={` position-absolute ${
                                    i18n.language === "English"
                                      ? "end"
                                      : "start"
                                  }-0 px-2`}
                                >
                                  <Badge
                                    badgeContent={profileData?.unreadMessages}
                                    showZero={false}
                                    style={{ margin: "0px 15px 0px 0px" }}
                                    color="error"
                                    overlap="circular"
                                  ></Badge>
                                </div>
                              )}
                              {item?.segment === paths.myProfile ? (
                                <>
                                  {!checkProfileComplete(profileData) ? (
                                    <div
                                      className={` position-absolute ${
                                        i18n.language === "English"
                                          ? "end"
                                          : "start"
                                      }-0 px-2`}
                                    >
                                      <Badge
                                        style={{ margin: "0px 15px 0px 0px" }}
                                        color="error"
                                        overlap="circular"
                                        variant="dot"
                                      ></Badge>
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                              {t(item.name)}
                            </a>
                          }
                          innerhtmlType={"html"}
                        ></ListItem>
                      </>
                    );
                  })
                : ""}
            </ul>
          </div>
          {component}
        </div>
      </div>
    </section>
  );
}
