import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchCrousel from "../SearchCrousel";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { initiateChat, likeDislikePost } from "../../../Reducer/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchSaved } from "../../../Reducer/savedSlice";
import ShareModal from "../ShareModal";
import {
  beginWithImage,
  findUploads,
  formatPrice,
  makeTagText,
} from "../../../utils/constants";

import { Button } from "reactstrap";
import { paths } from "../../../utils/paths";
export default function SearchItem({ data, rentType }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const savedCars = useSelector((state) => state.saved.saved)?.ads?.map(
    (v) => v._id
  );
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const getPage = (data) => {
    if (data?.adTypeName?.["en"] === "Sell") {
      return formatPrice(lang, data?.askPrice, data?.dollorAskPrice);
    } else {
      if (rentType === "daily") {
        return (
          formatPrice(lang, data?.askPrice, data?.dollorAskPrice) +
          `/ ${t("Day")}`
        );
      } else if (rentType === "weekly") {
        return (
          formatPrice(lang, data?.askPrice1, data?.dollorAskPrice1) +
          `/ ${t("Week")}`
        );
      } else {
        return (
          formatPrice(lang, data?.askPrice2, data?.dollorAskPrice2) +
          `/ ${t("Month")}`
        );
      }
    }
  };

  const [savedIcon, toggleSavedIcon] = useState(
    savedCars?.includes(data?._id) ? "heart_fill.png" : "heart.webp"
  );
  const [all_images, set_all_images] = useState([]);
  useEffect(() => {
    if (data) {
      const ad = data;
      let all_list = findUploads(ad)
        .map((v) => v?.thumbnail || v?.s3URL)
        .slice(0, 3);
      set_all_images(all_list);
    }
  }, [data]);

  const gotoSingleCar = () => {
    if (data?.adTypeName?.["en"] === "Sell") {
      navigate(`${paths.singleCar}?adId=${data?._id}`);
    } else {
      navigate(`${paths.singleRental}?adId=${data?._id}`);
    }
  };

  const toggleSaveCar = async () => {
    if (!localStorage.getItem("token")) {
      navigate("/login", {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }
    setLoading(true);
    const perform = savedCars?.includes(data?._id) ? "remove" : "add";
    const res = await likeDislikePost(data?._id, perform, setLoading);
    dispatch(fetchSaved());
    if (res)
      toggleSavedIcon(
        savedCars?.includes(data?._id) ? "heart.webp" : "heart_fill.png"
      );
  };
  const gotoSingleDealer = () => {
    if (data?.owner?.userType !== "dealer") return;

    navigate(`${paths.searchResultPage}?id=${data?.owner?._id}`);
  };
  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });
  const handleInitiateChat = (id) => {
    if (!localStorage.getItem("token")) {
      navigate("/login", {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }

    if (chatNow.loading) return;
    setChatNow({ ...chatNow, adId: id, loading: "true" });
    initiateChat(id)
      .then((res) => {
        if (res.status) {
          navigate(`${paths.messages}?id=${res.chatId}`);
        }
        setChatNow({ ...chatNow, loading: "false" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [sharePop, setSharePop] = useState(false);
  const handleCloseSharePop = () => {
    setSharePop(false);
  };

  return (
    <div class="car_set position-relative overflow-hidden">
      <div class="row carousel-margin">
        <SearchCrousel images={all_images ? all_images : []} id={data?._id} />
        <div class="col-sm-6">
          {data?.owner?.userType !== "dealer" && (
            <p
              className={`position-absolute top-0 ${
                lang === "en" ? "end" : "start"
              }-0`}
            >
              {" "}
              <span
                style={{
                  borderRadius:
                    lang === "en" ? "0px 0px 0px 10px" : "0px 0px 10px 0px",
                  marginTop: "-5px",
                  fontSize: "11px",
                  backgroundColor: "#334660",
                }}
                class=" text-light float-start px-4 py-1"
              >
                {t("Private ad")}
              </span>
            </p>
          )}
          <h6>
            {getPage(data)}{" "}
            <span style={{ marginTop: "-3px" }}>
              <Button
                id={`PopoverFocus${data?._id}`}
                type="button"
                onClick={() => setSharePop(true)}
                className="btn-sm btn-light"
                style={{ backgroundColor: "transparent", border: "unset" }}
              >
                <a
                // id="PopoverFocus"
                // onClick={() => openShareModal(data?._id)}
                >
                  <img
                    src="../assets/images/share.png"
                    alt="icon"
                    className="me-1"
                  />{" "}
                  {t("Share")}
                </a>{" "}
              </Button>
              <ShareModal
                data={data}
                open={sharePop}
                close={handleCloseSharePop}
              />
              {/* <UncontrolledPopover
                placement="bottom"
                target={`PopoverFocus${data?._id}`}
                trigger="focus"
              >
                <PopoverBody>
                  <div className="row">
                    <div className="col-3 d-flex justify-content-center">
                      <div>
                      <Icon icon="ph:paperclip-fill" className="rounded-5"
                          style={{ fontSize: '45px' }} />
                        <br />{" "}
                        <span style={{ fontSize: "12px" }}>{"Copy"}</span>
                      </div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <WhatsappShareButton
                        url={`${window.location.origin}/SingleCar?adId=${data?._id}`}
                      >
                        <WhatsappIcon
                          className="rounded-5"
                          style={{ width: "40px", height: "40px" }}
                        />{" "}
                        <br />{" "}
                        <span style={{ fontSize: "12px" }}>{"Whatsapp"}</span>
                      </WhatsappShareButton>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <FacebookMessengerShareButton
                        url={`${window.location.origin}/SingleCar?adId=${data?._id}`}
                      >
                        <FacebookIcon
                          className="rounded-5"
                          style={{ width: "40px", height: "40px" }}
                        />{" "}
                        <br />{" "}
                        <span style={{ fontSize: "12px" }}>
                          {t("Facebook")}
                        </span>
                      </FacebookMessengerShareButton>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <TwitterShareButton
                        url={`${window.location.origin}/SingleCar?adId=${data?._id}`}
                      >
                        <TwitterIcon
                          className="rounded-5"
                          style={{ width: "40px", height: "40px" }}
                        />{" "}
                        <br />{" "}
                        <span style={{ fontSize: "12px" }}>{"Twitter"}</span>
                      </TwitterShareButton>
                    </div>
                  </div>
                </PopoverBody>
              </UncontrolledPopover> */}
              {loading ? (
                <>
                  <button
                    class="btn btn-light"
                    style={{ width: "74px" }}
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-grow spinner-grow-sm"
                      style={{ marginTop: "-4px", marginRight: "14px" }}
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden" role="status">
                      Loading...
                    </span>
                  </button>
                </>
              ) : (
                <a onClick={() => toggleSaveCar()}>
                  <img
                    src={`../assets/images/${savedIcon}`}
                    className="me-1"
                    alt="icon"
                  />{" "}
                  {savedCars?.includes(data?._id) ? t("heartSaved") : t("Save")}
                </a>
              )}
            </span>
          </h6>
          {/* <h5>{data?.adTitle?.[lang]}</h5> */}
          <h5 className="fw-bold" style={{ fontSize: "20px" }}>
            {t(data?.properties?.basicDetails?.make?.["en"]) +
              " " +
              t(data?.properties?.basicDetails?.model?.["en"])}
          </h5>
          <p>{makeTagText(data, lang)}</p>
          <span
            class="view_car"
            style={{ cursor: "pointer" }}
            onClick={() => gotoSingleCar()}
          >
            {t("View car")}
          </span>
          <div class="cont">
            <div class="row">
              <div class="col-4">
                <p
                  style={{
                    cursor:
                      data?.owner?.userType === "dealer"
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => gotoSingleDealer()}
                >
                  <b>
                    {(data?.owner?.dealerShipName !== "N/A" &&
                      data?.owner?.dealerShipName) ||
                      data?.owner?.fullName}
                  </b>
                </p>
                <p>{t(data?.city)}</p>
                {/* <p>{Array(data.ratting ? data.ratting : 3 ).fill().map((_,i) =>{ return(<i class="fa-solid fa-star"></i>)})}</p> */}
              </div>

              <div class="col-8">
                {data?.owner?.phoneNumber && (
                  <p>
                    <a href={`tel:${data?.owner?.phoneNumber}`}>
                      <img src="../assets/images/call.png" alt="icon" />{" "}
                      {/* {`${t("Call")} ${data?.owner?.phoneNumber}`} */}
                      {`${lang === "en" ? "+" : ""}${data?.owner?.phoneNumber}${
                        lang === "en" ? "" : "+"
                      }`}
                    </a>
                  </p>
                )}
                {data?.owner?._id !== profileData?._id && (
                  <p>
                    <a
                      onClick={() => handleInitiateChat(data?._id)}
                      style={{
                        cursor: chatNow.loading ? "default" : "pointer",
                      }}
                    >
                      <img src="../assets/images/message.png" alt="icon" />{" "}
                      {chatNow.loading && chatNow.adId === data?._id
                        ? t("Please wait...")
                        : t("Chat now")}
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
